import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Login from './pages/Login/Login';
import Home from './pages/Login/Home';

/**
 *
 *  INICIO PAGINAS
 *
 */

const AlterarSenha = lazy(() => import('./pages/Geral/AlteraSenha'));
const ListarUsuarios = lazy(() => import('./pages/Geral/Usuarios/ListarUsuarios'));
const CadastrarUsuario = lazy(() => import('./pages/Geral/Usuarios/CadastrarUsuario'));
const CadastraarUsuariosPermicoes = lazy(() => import('./pages/Geral/Usuarios/CadastraarUsuariosPermicoes'));
const ListarUsuariosPermicoes = lazy(() => import('./pages/Geral/Usuarios/ListarUsuariosPermicoes'));
const DespesasDeViagem = lazy(() => import('./pages/Geral/DespesasDeViagem/DespesasDeViagem'));
const DespesasDeViagemGerenciar = lazy(() => import('./pages/Geral/DespesasDeViagem/DespesasDeViagemGerenciar'));

const CustoMaquinas = lazy(() => import('./pages/Operacional/CustoMaquinas'));

const Orcamento = lazy(() => import('./pages/Operacional/Orcamento/Orcamento'));
const OrcamentoLista = lazy(() => import('./pages/Operacional/Orcamento/OrcamentoLista'));
const OrcamentoDeslocamento = lazy(() => import('./pages/Operacional/Orcamento/OrcamentoDeslocamento'));
const OrcamentoServico = lazy(() => import('./pages/Operacional/Orcamento/OrcamentoServico'));
const OrcamentoUsuario = lazy(() => import('./pages/Operacional/Orcamento/OrcamentoUsuario'));

const CentroCustoGrupos = lazy(() => import('./pages/Operacional/CentroCustoGrupos/CentroCustoGrupos'));
const CentroCustoCriacaoGrupos = lazy(() => import('./pages/Operacional/CentroCustoGrupos/CentroCustoCriacaoGrupos'));

const Esboco = lazy(() => import('./pages/Compras/Esboco'));
const Estoque = lazy(() => import('./pages/Compras/Estoque'));
const Aprovacoes = lazy(() => import('./pages/Compras/Aprovacoes'));
const TempoAprovacao = lazy(() => import('./pages/Compras/TempoAprovacao'));

const RelatoriosGerais = lazy(() => import('./pages/Geral/RelatoriosGerais'));

const EmissorDeFatura = lazy(() => import('./pages/Faturamento/EmissorDeFatura'));
const ListarOrdensCarregamento = lazy(() => import('./pages/Faturamento/OrdemCarregamento/ListarOrdensCarregamento'));
const CadastraOrdemCarregamento = lazy(() => import('./pages/Faturamento/OrdemCarregamento/CadastraOrdemCarregamento'));
const AgendamentoOrdemCarregamento = lazy(() => import('./pages/Faturamento/OrdemCarregamento/AgendamentoOrdemCarregamento'));
const ListarAgendamentoOrdem = lazy(() => import('./pages/Faturamento/OrdemCarregamento/ListarAgendamentoOrdem'));
const AcompanhamentoOrdens = lazy(() => import('./pages/Faturamento/OrdemCarregamento/AcompanhamentoOrdens'));

const FluxoCaixaPrevisao = lazy(() => import('./pages/Financeiro/FluxoCaixaPrevisao/FluxoCaixaPrevisao'));
const FluxoCaixaRealizados = lazy(() => import('./pages/Financeiro/FluxoCaixaRealizados/FluxoCaixaRealizados'));

const DashboarLogistica = lazy(() => import('./pages/Logistica/Dashboard/DashboardLogistica'));
const CalculoDeVenda = lazy(() => import('./pages/Logistica/Calculos/CalculoDeVenda'));
const ListagemID = lazy(() => import('./pages/Logistica/Calculos/ListagemID'));

const ListarRotas = lazy(() => import('./pages/Logistica/Rotas/ListarRotas'));
const CadastroRotas = lazy(() => import('./pages/Logistica/Rotas/CadastroRotas'));
const AgendaCrm = lazy(() => import('./pages/Logistica/AgendaCRM/AgendaCrm'));
const CompraVendaBiomassa = lazy(() => import('./pages/Logistica/CompraVendaBiomassa'));
const Abastecimentos = lazy(() => import('./pages/Logistica/Abastecimentos'));
const ViagensDiarias = lazy(() => import('./pages/Logistica/ViagensDiarias/ViagensDiarias'));
const ViagensDiariasMeses = lazy(() => import('./pages/Logistica/ViagensDiarias/ViagensDiariasMeses'));
const ViagensDiariasMotivos = lazy(() => import('./pages/Logistica/ViagensDiarias/ViagensDiariasMotivos'));
const CalculoRota = lazy(() => import('./pages/Logistica/CalculoRota/CalculoRota'));
const CalculoRotaConfiguracoes = lazy(() => import('./pages/Logistica/CalculoRota/CalculoRotaConfiguracoes'));
const CanhotoNfeCte = lazy(() => import('./pages/Logistica/CanhotoNfeCte/CanhotoNfeCte'));
const CanhotoNfeCteViagensEnvio = lazy(() => import('./pages/Logistica/CanhotoNfeCte/CanhotoNfeCteViagensEnvio'));
const CanhotoNfeCteAdmin = lazy(() => import('./pages/Logistica/CanhotoNfeCte/CanhotoNfeCteAdmin'));
const CanhotoNfeCteCreditoDebitoInformacoes = lazy(() => import('./pages/Logistica/CanhotoNfeCte/CanhotoNfeCteCreditoDebitoInformacoes'));
const CadastroChecklistMotorista = lazy(() => import('./pages/Logistica/ChecklistMotorista/CadastroChecklistMotorista'));
const CadastroChecklistMotoristaLista = lazy(() => import('./pages/Logistica/ChecklistMotorista/CadastroChecklistMotoristaLista'));
const EnvioCheckListMotorista = lazy(() => import('./pages/Logistica/ChecklistMotorista/EnvioCheckListMotorista'));
const ChecklistMotoristaEnviados = lazy(() => import('./pages/Logistica/ChecklistMotorista/ChecklistMotoristaEnviados'));
const ChecklistMotoristaPlanosAcaoLista = lazy(() => import('./pages/Logistica/ChecklistMotorista/ChecklistMotoristaPlanosAcaoLista'));
const OrdemEmbarque = lazy(() => import('./pages/Logistica/OrdemEmbarque/OrdemEmbarque'));
const OrdemEmbarqueOrdem = lazy(() => import('./pages/Logistica/OrdemEmbarque/OrdemEmbarqueOrdem'));
const OrdemEmbarqueMarca = lazy(() => import('./pages/Logistica/OrdemEmbarque/Cadastros/OrdemEmbarqueMarca'));
const OrdemEmbarqueModelo = lazy(() => import('./pages/Logistica/OrdemEmbarque/Cadastros/OrdemEmbarqueModelo'));
const OrdemEmbarqueEquipamento = lazy(() => import('./pages/Logistica/OrdemEmbarque/Cadastros/OrdemEmbarqueEquipamento'));
const OrdemEmbarqueCheckList = lazy(() => import('./pages/Logistica/OrdemEmbarque/Cadastros/OrdemEmbarqueCheckList'));

const GestaoDocumentos = lazy(() => import('./pages/Administrativo/GestaoDocumentos/GestaoDocumentos'));
const GestaoDocumentosCadastroDocumentos = lazy(() => import('./pages/Administrativo/GestaoDocumentos/GestaoDocumentosCadastroDocumentos'));
const GestaoPatio = lazy(() => import('./pages/Administrativo/GestaoPatio/GestaoPatio'));
const GestaoPatioConfiguracao = lazy(() => import('./pages/Administrativo/GestaoPatio/GestaoPatioConfiguracao'));
const GestaoPatioAdministrar = lazy(() => import('./pages/Administrativo/GestaoPatio/GestaoPatioAdministrar'));

const CaixaContabilidade = lazy(() => import('./pages/Contabilidade/CaixaContabilidade'));
const Ebit = lazy(() => import('./pages/Contabilidade/Ebit'));
const Ebitda = lazy(() => import('./pages/Contabilidade/Ebitda'));
const DatasImpostos = lazy(() => import('./pages/Contabilidade/DatasImpostos'));
const RelatorioComissaoCadastros = lazy(() => import('./pages/Contabilidade/RelatorioComissaoCadastros'));
const RelatorioNegocio = lazy(() => import('./pages/Contabilidade/RelatorioNegocio/RelatorioNegocio'));

const ListaPedidos = lazy(() => import('./pages/EcoCane/Pedidos/ListaPedidos'));
const ConsutaPedidos = lazy(() => import('./pages/EcoCane/Pedidos/ConsultaPedidos'));
const ConsultaClinetesFinal = lazy(() => import('./pages/EcoCane/Pedidos/ClientesFinal/ConsultaClientesFinal'));
const ListaClinetesFinal = lazy(() => import('./pages/EcoCane/Pedidos/ClientesFinal/ListaClientesFinal'));
const ListaOrdemProducao = lazy(() => import('./pages/EcoCane/OrdemProducao/ListaOrdemProducao'));
const CadastraOrdemProducao = lazy(() => import('./pages/EcoCane/OrdemProducao/CadastraOrdemProducao'));
const ParadaDeProducao = lazy(() => import('./pages/EcoCane/OrdemProducao/Complementos/ParadaDeProducao'));
const SaidasEMedidas = lazy(() => import('./pages/EcoCane/OrdemProducao/Complementos/SaidasEMedidas'));
const TiposMontagem = lazy(() => import('./pages/EcoCane/OrdemProducao/Complementos/TiposMontagem'));

const AmostraLaboratorio = lazy(() => import('./pages/EcoCane/AnaliseLaboratorio/AmostraLaboratorio'));
const AmostraLaboratorioDatas = lazy(() => import('./pages/EcoCane/AnaliseLaboratorio/AmostraLaboratorioDatas'));
const AnaliseLaboratorio = lazy(() => import('./pages/EcoCane/AnaliseLaboratorio/AnaliseLaboratorio'));
const AnaliseLaboratorioItens = lazy(() => import('./pages/EcoCane/AnaliseLaboratorio/AnaliseLaboratorioItens'));
const AnaliseLaboratorioDatas = lazy(() => import('./pages/EcoCane/AnaliseLaboratorio/AnaliseLaboratorioDatas'));

const Requisicoes = lazy(() => import('./pages/Administrativo/RequisicaoCompra/ListaRequisicao'));
const RequisicaoCompraCadastro = lazy(() => import('./pages/Administrativo/RequisicaoCompra/RequisicaoCompraCadastro'));
const CadastroFornecedor = lazy(() => import('./pages/Administrativo/RequisicaoCompra/CadastroFornecedor'));

/**
 *
 *  FIM PAGINAS
 *
 */

export default function Routes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Carregando Pagina...</div>}>

                <Route path="/" exact component={Login} />
                <Route path="/login" component={Login} />
                <Route path="/home" component={Home} />

                <Switch>
                    <Route path="/geral/alterarsenha" exact component={AlterarSenha} />
                    <Route path="/geral/usuarios" exact component={ListarUsuarios} />
                    <Route path="/geral/usuarios/novo" exact component={CadastrarUsuario} />
                    <Route path="/geral/usuarios/id/:id" exact component={CadastrarUsuario} />
                    <Route path="/geral/usuariospermicoes" exact component={ListarUsuariosPermicoes} />
                    <Route path="/geral/usuariospermicoes/:id" exact component={CadastraarUsuariosPermicoes} />
                    <Route path="/geral/relatorios" component={RelatoriosGerais} />
                    <Route path="/geral/despesasDeViagem" component={DespesasDeViagem} />
                    <Route path="/geral/despesasDeViagemGerenciar" component={DespesasDeViagemGerenciar} />
                </Switch>

                <Switch>
                    <Route path="/operacional/customaquinas" exact component={CustoMaquinas} />
                    <Route path="/operacional/centrocustocrupos" exact component={CentroCustoGrupos} />
                    <Route path="/operacional/centrocustocrupos/criacao" exact component={CentroCustoCriacaoGrupos} />
                    <Route path="/operacional/orcamento/servico" exact component={OrcamentoServico} />
                    <Route path="/operacional/orcamento/deslocamento" exact component={OrcamentoDeslocamento} />
                    <Route path="/operacional/orcamento/usuario" exact component={OrcamentoUsuario} />
                    <Route path="/operacional/orcamento/lista" exact component={OrcamentoLista} />
                    <Route path="/operacional/orcamento" exact component={Orcamento} />
                    <Route path="/operacional/orcamento/:id" exact component={Orcamento} />
                </Switch>

                <Switch>
                    <Route path="/compras/esboco" exact component={Esboco} />
                    <Route path="/compras/estoque" exact component={Estoque} />
                    <Route path="/compras/aprovacoes" exact component={Aprovacoes} />
                    <Route path="/compras/tempoAprovacao" exact component={TempoAprovacao} />
                </Switch>

                <Switch>
                    <Route path="/ecocane/pedidos" exact component={ListaPedidos} />
                    <Route path="/ecocane/pedidos/novo" exact component={ConsutaPedidos} />
                    <Route path="/ecocane/pedidos/id/:id" component={ConsutaPedidos} />
                    <Route path="/ecocane/clientesfinal/" exact component={ListaClinetesFinal} />
                    <Route path="/ecocane/clientesfinal/novo" exact component={ConsultaClinetesFinal} />
                    <Route path="/ecocane/clientesfinal/id/:id" component={ConsultaClinetesFinal} />
                    <Route path="/ecocane/ordemproducao/outros/paradaproducao" exact component={ParadaDeProducao} />
                    <Route path="/ecocane/ordemproducao/outros/saidasemedidas" exact component={SaidasEMedidas} />
                    <Route path="/ecocane/ordemproducao/outros/tiposmontagem" exact component={TiposMontagem} />
                    <Route path="/ecocane/ordemproducao" exact component={ListaOrdemProducao} />
                    <Route path="/ecocane/ordemproducao/ordem/:id" component={CadastraOrdemProducao} />
                    <Route path="/ecocane/analiseLaboratorio" exact component={AnaliseLaboratorioDatas} />
                    <Route path="/ecocane/analiseLaboratorio/itens" exact component={AnaliseLaboratorioItens} />
                    <Route path="/ecocane/analiseLaboratorio/:data" exact component={AnaliseLaboratorio} />
                    <Route path="/ecocane/amostraLaboratorio/:data" exact component={AmostraLaboratorio} />
                    <Route path="/ecocane/amostraLaboratorio" exact component={AmostraLaboratorioDatas} />
                </Switch>

                <Switch>
                    <Route path="/logistica/dashboard" component={DashboarLogistica} />
                    <Route path="/logistica/calculovenda/" exact component={CalculoDeVenda} />
                    <Route path="/logistica/calculovenda/:id" exact component={ListagemID} />

                    <Route path="/logistica/rotas/" exact component={ListarRotas} />
                    <Route path="/logistica/rotas/:id/:empresa" component={CadastroRotas} />
                    <Route path="/logistica/agendacrm" component={AgendaCrm} />
                    <Route path="/logistica/compravendabiomassa" component={CompraVendaBiomassa} />
                    <Route path="/logistica/abastecimentos" component={Abastecimentos} />
                    {/* viagens diarias */}
                    <Route path="/logistica/viagensDiarias" component={ViagensDiarias} />
                    <Route path="/logistica/viagensDiariasMeses" component={ViagensDiariasMeses} />
                    <Route path="/logistica/viagensDiariasMotivos" component={ViagensDiariasMotivos} />
                    {/* canhoto nfe cte viagens */}
                    <Route path="/logistica/canhotoNfeCteViagens" exact component={CanhotoNfeCte} />
                    <Route path="/logistica/canhotoNfeCteViagens/envio/:tipo/:numdoc" exact component={CanhotoNfeCteViagensEnvio} />
                    <Route path="/logistica/canhotoNfeCteViagens/admin" exact component={CanhotoNfeCteAdmin} />
                    <Route path="/logistica/canhotoNfeCteViagens/creditoEDebito/:placa" exact component={CanhotoNfeCteCreditoDebitoInformacoes} />
                    {/* check list motorista */}
                    <Route path="/logistica/checklistmotorista" exact component={CadastroChecklistMotoristaLista} />
                    <Route path="/logistica/checklistmotorista/enviar" exact component={EnvioCheckListMotorista} />
                    <Route path="/logistica/checklistmotorista/gerenciar" exact component={ChecklistMotoristaEnviados} />
                    <Route path="/logistica/checklistmotorista/planosacao" exact component={ChecklistMotoristaPlanosAcaoLista} />
                    <Route path="/logistica/checklistmotorista/novo" exact component={CadastroChecklistMotorista} />
                    <Route path="/logistica/checklistmotorista/:id" exact component={CadastroChecklistMotorista} />
                    {/* OrdemEmbarque */}
                    <Route path="/logistica/ordemembarque" exact component={OrdemEmbarque} />
                    <Route path="/logistica/ordemembarque/marca" exact component={OrdemEmbarqueMarca} />
                    <Route path="/logistica/ordemembarque/modelo" exact component={OrdemEmbarqueModelo} />
                    <Route path="/logistica/ordemembarque/equipamento" exact component={OrdemEmbarqueEquipamento} />
                    <Route path="/logistica/ordemembarque/checklist" exact component={OrdemEmbarqueCheckList} />
                    <Route path="/logistica/ordemembarque/novo" exact component={OrdemEmbarqueOrdem} />
                    <Route path="/logistica/ordemembarque/:id" exact component={OrdemEmbarqueOrdem} />
                    <Route path="/logistica/calculorota" exact component={CalculoRota} />
                    <Route path="/logistica/calculorota/configuracoes" exact component={CalculoRotaConfiguracoes} />
                </Switch>

                <Switch>
                    {/* GestaoDocumentos */}
                    <Route path="/administrativo/gestaodocumentos" exact component={GestaoDocumentos} />
                    <Route path="/administrativo/gestaodocumentos/documentos" exact component={GestaoDocumentosCadastroDocumentos} />
                    {/* GestaoPatio */}
                    <Route path="/administrativo/gestaopatio" exact component={GestaoPatio} />
                    <Route path="/administrativo/gestaopatio/configuracao" exact component={GestaoPatioConfiguracao} />
                    <Route path="/administrativo/gestaopatio/administrar" exact component={GestaoPatioAdministrar} />
                </Switch>

                <Switch>
                    <Route path="/contabilidade/caixa" component={CaixaContabilidade} />
                    <Route path="/contabilidade/ebit/consolidado" component={Ebit} />
                    <Route path="/contabilidade/ebit/empresa/:empresa" component={Ebitda} />
                    <Route path="/contabilidade/datasimpostos" component={DatasImpostos} />
                    <Route path="/contabilidade/relatorioComissaoCadastros" component={RelatorioComissaoCadastros} />
                    <Route path="/contabilidade/relatorioNegocio" component={RelatorioNegocio} />
                </Switch>

                <Switch>
                    <Route path="/financeiro/fluxocaixaRealizados" component={FluxoCaixaRealizados} />
                    <Route path="/financeiro/fluxocaixaprevisao" component={FluxoCaixaPrevisao} />
                </Switch>

                <Switch>
                    <Route path="/faturamento/emissorfatura" component={EmissorDeFatura} />
                    <Route path="/faturamento/cadastraOrdemCarregamento/" exact component={ListarOrdensCarregamento} />
                    <Route path="/faturamento/cadastraOrdemCarregamento/novo" exact component={CadastraOrdemCarregamento} />
                    <Route path="/faturamento/cadastraOrdemCarregamento/:id" component={CadastraOrdemCarregamento} />
                    <Route path="/faturamento/agendamentoOrdemCarregamento" exact component={ListarAgendamentoOrdem} />
                    <Route path="/faturamento/agendamentoOrdemCarregamento/novo" exact component={AgendamentoOrdemCarregamento} />
                    <Route path="/faturamento/agendamentoOrdemCarregamento/:id" exact component={AgendamentoOrdemCarregamento} />
                    <Route path="/faturamento/agendamentoOrdemCarregamento/agendado/:data" exact component={AcompanhamentoOrdens} />
                </Switch>

                <Switch>
                    <Route path="/administrativo/requisicoes" exact component={Requisicoes} />
                    <Route path="/administrativo/requisicoes/cadastro" exact component={RequisicaoCompraCadastro} />
                    <Route path="/administrativo/requisicoes/:id" exact component={RequisicaoCompraCadastro} />
                    <Route path="/administrativo/cadastrofornecedor" exact component={CadastroFornecedor} />
                    <Route path="/administrativo/editaFornecedor/:id" exact component={CadastroFornecedor} />
                </Switch>

                {/* <Route path="*" component={Home} /> */}

            </Suspense>
        </BrowserRouter>
    );
}